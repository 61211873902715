export const getCheckBoxStatus = (res, ids = []) => {
  const keys = {};
  res.map(item => {
    // 给折叠面板赋初始值
    keys[item.code] = ["1"];
    // 给checkBox添加v-model依赖属性
    item.check = !!~ids.indexOf(item.id);

    if (item.children) {
      getCheckBoxStatus(item.children, ids);
    }
  });

  return { keys, tree: res };
};
