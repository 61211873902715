// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    name: [
      {
        required: true,
        trigger: "blur",
        message: "角色名称不能为空"
      }
    ]
  };
  return rules;
};
