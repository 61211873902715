<template>
  <div>
    <xModal
      title="新增角色"
      :visible="visible"
      :w="500"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rules="rules"
          class="form-box"
        >
          <!-- 角色名称 -->
          <FormItem label="角色名称" name="name">
            <a-input
              placeholder="请输入"
              v-model:value="formState.name"
              style="width:320px;"
            />
          </FormItem>

          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              class="ml-16"
              type="primary"
              @click="handleSubmit"
              :loading="isLoading"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form, message } from "ant-design-vue";
import { getInvoiceRules } from "./config";
import xModal from "@/components/xModal";
import $await from "@/utils/await";
import { useAddRoleApi } from "@/apis/system";

export default {
  props: {
    visible: { type: Boolean, default: false }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item
  },
  setup(props, ctx) {
    const addRoleApi = useAddRoleApi();

    const isLoading = ref(false);
    const formRef = ref();

    // 表单信息
    const formState = ref({
      name: "",
      visible: true
    });

    const rules = getInvoiceRules(formState);

    // 提交表单
    const handleSubmit = () => {
      isLoading.value = true;
      formRef.value.validate().then(async () => {
        const [, res] = await $await(
          addRoleApi({ ...formState.value, code: formState.value.name })
        );
        if (res) {
          isLoading.value = false;
          message.success("新增成功！");
          // 更新列表
          ctx.emit("getData");
          // 关闭弹窗
          handleCancel();
        } else {
          isLoading.value = false;
        }
      });
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    return {
      isLoading,
      formRef,
      formState,
      handleCancel,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      handleSubmit,
      rules
    };
  }
};
</script>

<style lang="less" scoped>
.input-grey {
  width: 320px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  line-height: 32px;
}
</style>
<style lang="less"></style>
